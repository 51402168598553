<template>
  <div>
    <client-only>
      <HgWatadoSearch
        allowHotjarRecord
        :is-mobile="isMobile"
        :is-loading-destinations="$apollo.queries.autosuggestAmadeus.loading"
        :destinations="orderedDestinations"
        :search-url="amadeusHotelSearchUrl"
        :roomLabel="$t('amadeusPackageTourSearch.roomLabel')"
        :infoTextSingleRoom="$t('amadeusPackageTourSearch.infoTextSingleRoom')"
        :infoTextSingles="$t('amadeusPackageTourSearch.infoTextSingles')"
        :infoTextSingleRoomMultiple="
          $t('amadeusPackageTourSearch.infoTextSingleRoomMultiple')
        "
        :infoTextMultipleSingular="
          $t('amadeusPackageTourSearch.infoTextMultipleSingular')
        "
        :infoTextMultiplePlural="
          $t('amadeusPackageTourSearch.infoTextMultiplePlural')
        "
        :showRooms="['de', 'at'].includes($i18n.locale)"
        @onUpdateDestinationTerm="updateDestinationTerm"
        @onUpdateSearchParameters="updateSearchParameters"
        @onTriggerSearch="triggerSearch"
      />
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';
import autosuggestAmadeus from '@/apollo/queries/destination/suggest.gql';
import autosuggestDefaultAmadeusQuery from '@/apollo/queries/destination/defaultDestinations';
import amadeusHotelSearchUrlQuery from '@/apollo/queries/ibe/AmadeusHotelSearchUrl.gql';
import { format } from 'date-fns';

export default {
  mixins: [GtmEventsMixin],
  data: () => ({
    destinationTerm: '',
    destinations: [],
    destination: null,
    watadoSearchUrl: '',
    selectedDestination: null,
    selectedDaterange: null,
    selectedTravelers: { adults: 2 },
    selectedDuration: null,
    autosuggestAmadeus: [],
    searchUrl: '',
    orderedDestinations: [],
    hasParams: false,
    orderTypes: [
      'airport',
      'city',
      'continent',
      'country',
      'high_level_region',
      'metro_station',
      'multi_city_vicinity',
      'neighborhood',
      'point_of_interest',
      'province_state',
      'train_station',
      'accommodation'
    ]
  }),

  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },

  watch: {
    autosuggestAmadeus() {
      if (this.autosuggestAmadeus && this.autosuggestAmadeus.length > 0) {
        // Get relevant types
        const orderTypes = [];
        this.autosuggestAmadeus.forEach((elem) => {
          if (!orderTypes.includes(elem.type)) {
            orderTypes.push(elem.type);
          }
        });

        // Order results by types
        const ordered = [];
        orderTypes.forEach((element) => {
          ordered.push(
            this.autosuggestAmadeus.filter(function(elem) {
              return elem.type === element;
            })
          );
        });

        this.orderedDestinations = [].concat(...ordered);
      } else if (
        this.autosuggestDefaultAmadeus &&
        this.autosuggestDefaultAmadeus.length
      )
        this.orderedDestinations = this.autosuggestDefaultAmadeus;
    }
  },

  mounted() {
    if (this.autosuggestDefaultAmadeus && this.autosuggestDefaultAmadeus.length)
      this.orderedDestinations = this.autosuggestDefaultAmadeus;
  },

  methods: {
    updateSearchParameters(params) {
      const { destination, dateRange, travelers, duration } = params;

      this.destination = destination;
      if (this.destination && this.destination.__typename) {
        delete this.destination.__typename;
        delete this.destination.villageName;
        delete this.destination.provinceName;
        delete this.destination.countryName;
        delete this.destination.destinationName;
      }
      this.selectedDaterange = dateRange;
      if (travelers) {
        this.selectedTravelers = travelers;
      }
      this.selectedDuration = duration;
      this.hasParams = true;
    },

    triggerSearch(event) {
      this.$gtm.push({
        event: 'TRIPS_search_used',
        destination:
          this.destination && this.destination.name
            ? this.destination.name
            : '',
        period: `${format(
          this.selectedDaterange.start,
          'EE, dd.MM.yyyy'
        )} - ${format(this.selectedDaterange.end, 'EE, dd.MM.yyyy')}`,
        travellers: this.selectedTravelers,
        duration: this.selectedDuration
      });
    },

    updateDestinationTerm(term) {
      this.destinationTerm = term;
    }
  },
  apollo: {
    autosuggestAmadeus: {
      skip() {
        return !this.destinationTerm.length;
      },
      debounce: 300,
      query: autosuggestAmadeus,
      variables() {
        return {
          search: this.destinationTerm,
          productType: 'HOTEL'
        };
      }
    },
    amadeusHotelSearchUrl: {
      query: amadeusHotelSearchUrlQuery,
      skip() {
        return (
          !this.selectedDaterange ||
          !this.selectedDaterange.start ||
          !this.selectedDaterange.end
        );
      },
      variables() {
        return {
          input: {
            numberOfAdults: this.selectedTravelers.adults,
            agesOfChildren: this.selectedTravelers.ages,
            room: this.selectedTravelers.rooms,
            startDate: this.$dateFns.formatISO(this.selectedDaterange.start, {
              representation: 'date'
            }),
            endDate: this.$dateFns.formatISO(this.selectedDaterange.end, {
              representation: 'date'
            }),
            destination: this.destination && {
              id: this.destination.id,
              type:
                this.destination.type == 'favoritesdefault'
                  ? 'Destination'
                  : this.destination.type,
              name: this.destination.name,
              iffRegionId: this.destination.iffRegionId,
              regionId: this.destination.iffRegionId,
              suggestion: this.destination.suggestion,
              countryISO3166: this.destination.id
            },
            duration: this.selectedDuration || ''
          }
        };
      }
    },
    autosuggestDefaultAmadeus: {
      query: autosuggestDefaultAmadeusQuery,
      variables() {
        return {
          type: 'HOTEL'
        };
      }
    }
  }
};
</script>
